<template>
	<div class="col-lg-4 mb-3 pr-0" @click="onSelect">
		<z-card :selected="productPeriodIdsForStudentIndex.includes(String(productPeriod.id))" :disabled="!productPeriod.available_dates.length">
			<template v-if="isCourse">
				<div class="place" v-if="productPeriod.place">
					<strong class="bigger">
						{{ productPeriod.place.name }}
					</strong>
					<br />
					{{ productPeriod.place.street }} <br />
					<span v-if="productPeriod.place.zip != '0'">{{ productPeriod.place.zip }}</span>
					<span v-if="productPeriod.place.town">, {{ productPeriod.place.town }}</span>
				</div>
				<div class="mt-3 mb-2 semester">
					<strong>{{ productPeriod.name }}</strong>
				</div>
				<div class="date">{{ formatDate(productPeriod.start_date) }} – {{ formatDate(productPeriod.end_date) }}</div>
			</template>

			<template v-else>
				<div v-if="!productPeriod.available_dates.length" class="font-weight-bold text-danger mb-3">
					Turnus je zaplnený.
				</div>

				<div v-if="isLevelUp">
					Kurz
				</div>
				<div v-else>
					Turnus
					<z-tooltip text="Ak objednáte tábor pre 2 a viac detí, alebo vyberiete 2 a viac turnusov, bude Vám automaticky započítaná množstevná zľava." />
				</div>

				<strong v-if="isCamp" class="date bigger">{{ productPeriod.name }}</strong>
				<strong v-else class="date bigger">{{ formatDate(productPeriod.start_date, 'DD.MM') }} – {{ formatDate(productPeriod.end_date) }}</strong>
				<hr />
				<div class="z-small-text">
					<strong>
						<span v-if="this.type == 'levelup' || this.type == 'levelup-1rocnik'">
							Kurz
						</span>
						<span v-else-if="!isOnlineCourse">
							Adresa
						</span>
						{{ productName }}</strong
					>
				</div>
				<div v-if="productPeriod.place" class="place z-small-text">
					{{ productPeriod.place.street }} <br />
					<span v-if="productPeriod.place.zip !== '0'">{{ productPeriod.place.zip }}</span>
					<span v-if="productPeriod.place.town">, {{ productPeriod.place.town }}</span>
				</div>

				<div v-if="productPeriod.is_statistics_enabled">
					<hr />
					<div class="d-flex justify-content-between font-weight-bold z-small-text">
						<div>
							Naplnenosť turnusu
							<z-tooltip :text="'Vekový priemer: ' + productPeriod.statistics.average_age + ' rokov'" icon="person-circle" />
						</div>
						<span>{{ productPeriod.statistics.occupancy_percentage }}%</span>
					</div>
					<b-progress :value="productPeriod.statistics.occupancy_percentage" height="9px" variant="warning" class="mb-3 mt-2"></b-progress>
				</div>
				<div v-if="productPeriod.is_statistics_enabled && (productPeriod.statistics.genders.females_percentage || productPeriod.statistics.genders.males_percentage)">
					<hr />
					<div class="d-flex justify-content-between z-small-text font-weight-bold mb-2">
						Pomer dievčatá/chlapci
					</div>
					<div class="d-flex">
						<div class="text-center mr-2 pb-4 position-relative">
							<img src="./img/female.svg" class="d-inline-block" />
							<span class="z-small-text text-percent">{{ productPeriod.statistics.genders.females_percentage }}%</span>
						</div>
						<div class="w-100">
							<b-progress class="mt-2" max="100" height="9px">
								<b-progress-bar :value="productPeriod.statistics.genders.females_percentage" class="z-bar-female"></b-progress-bar>
								<b-progress-bar :value="productPeriod.statistics.genders.males_percentage" class="z-bar-male"></b-progress-bar>
							</b-progress>
						</div>
						<div class="text-center ml-2 position-relative">
							<img src="./img/male.svg" class="d-inline-block" />
							<span class="z-small-text text-percent">{{ productPeriod.statistics.genders.males_percentage }}%</span>
						</div>
					</div>
				</div>
			</template>
		</z-card>
	</div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import productUtils from '@/plugins/app/_utils/product.util.js'

export default {
	components: {
		'z-card': () => import('@/plugins/app@components/card/z-card.vue'),
		'z-tooltip': () => import('@/plugins/app@components/tooltip/z-tooltip.vue')
	},

	props: {
		productPeriod: {
			type: Object,
			required: true
		},
		productName: {
			type: String,
			required: true
		},
		studentIndex: {
			type: [String, Number],
			required: true
		}
	},

	computed: {
		...mapGetters('order', ['orderRegistrationsForStudentIndex']),

		type() {
			return this.$route.params.type
		},

		productPeriodIdsForStudentIndex() {
			return this.orderRegistrationsForStudentIndex(this.studentIndex).map((or) => String(or.product_period_id))
		},

		isCourse() {
			return productUtils.isCourse(this.type)
		},
		isOnlineCourse() {
			return productUtils.isOnlineCourse(this.type)
		},
		isLevelUp() {
			return productUtils.isLevelUp(this.type)
		},
		isCamp() {
			return productUtils.isCamp(this.type)
		}
	},

	methods: {
		formatDate(date, format = 'DD.MM.YYYY') {
			return moment(date).format(format)
		},

		onSelect() {
			if (!this.productPeriod.available_dates.length) return

			this.$emit('click')
		}
	}
}
</script>

<style lang="sass" scoped>
.text-percent
	font-weight: bold
	position: absolute
	left: 50%
	bottom: 0
	transform: translateX(-50%)

.info-btn
	font-size: 13px
	line-height: 1
	margin-top: -12px
</style>
